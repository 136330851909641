import React, { useState, useEffect, useRef } from 'react';
import { Slider, Stack, Typography, IconButton, Box, Popover, List, useMediaQuery, ListItemButton, Tooltip, Button } from '@mui/material';
import { PlayArrowRounded, PauseRounded, VolumeUpRounded, VolumeDownRounded, VolumeOffRounded  } from '@mui/icons-material';
import Forward10RoundedIcon from '@mui/icons-material/Forward10Rounded';
import Replay10RoundedIcon from '@mui/icons-material/Replay10Rounded';

function AudioPlayer({ src, sub, dur }) {
    const mobile = useMediaQuery('(max-width:730px)');
    const audioRef = useRef(); 
    const [playing, setPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(1);
    const [muted, setMuted] = useState(false);
    const [speed, setSpeed] = useState(1);
    const [speedAnchorEl, setSpeedAnchorEl] = useState(null);
    const speedPopOpen = Boolean(speedAnchorEl);
    const speedId = speedPopOpen ? 'speed-popover' : undefined;

  const formatTime = (time) => {
    const roundedTime = Math.floor(time);
    const minutes = Math.floor(roundedTime / 60);
    const seconds = roundedTime % 60;
    return minutes + ':' + ('0' + seconds).slice(-2);
  };

  useEffect(() => {
    const audio = audioRef.current;
  
    const setAudioData = () => {
      setDuration(audio.duration);
      //console.log("duration", audio.duration);
      setProgress(audio.currentTime);
    }
  
    const checkAudioProgress = () => {
      setProgress(audio.currentTime);
    }
  
    audio.addEventListener("loadedmetadata", setAudioData);
    audio.addEventListener("timeupdate", checkAudioProgress);
  
    return () => {
      audio.removeEventListener("loadedmetadata", setAudioData);
      audio.removeEventListener("timeupdate", checkAudioProgress);
    }
  }, []);
  

   //useEffect for speed change
   useEffect(() => {
        if (audioRef && audioRef.current) {
            audioRef.current.playbackRate = speed;
        }
    }, [speed, audioRef]);

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (playing) {
      audio.pause();
    } else {
      audio.play();
    }
    setPlaying(!playing);
  }

  const changeAudioProgress = (event, newValue) => {
    const audio = audioRef.current;
    audio.currentTime = newValue;
    setProgress(audio.currentTime);
  }

  const forwardAudio = () => {
    const audio = audioRef.current;
    audio.currentTime = Math.min(audio.currentTime + 10, duration);
    setProgress(audio.currentTime);
  }

  const rewindAudio = () => {
    const audio = audioRef.current;
    audio.currentTime = Math.max(audio.currentTime - 10, 0);
    setProgress(audio.currentTime);
  }

  const changeVolume = (event, newValue) => {
    const audio = audioRef.current;
    audio.volume = newValue;
    setVolume(newValue);
    setMuted(newValue === 0 ? true : false);
  }

  const toggleMute = () => {
    const audio = audioRef.current;
    if (muted) {
      audio.volume = volume;
      setMuted(false);
    } else {
      audio.volume = 0;
      setMuted(true);
    }
  }

  return (
    <Box>
        <Tooltip title={localStorage.getItem("sub") !=='pro' && localStorage.getItem("sub") !=='premium' ? "Upgrade to Pro to access audio player" : ""} placement="top" followCursor={true}>
        {!mobile ? <Stack direction="row" alignItems="center" spacing={2} px={1} mt={0.3}>
            <Stack direction="row" alignItems="center" spacing={1}>
                <IconButton disabled={localStorage.getItem("sub") !=='pro' && localStorage.getItem("sub") !=='premium'} onClick={rewindAudio}>
                    <Replay10RoundedIcon sx={{color: "primary.main"}}/>
                </IconButton>
                <IconButton disabled={sub!=='premium' && sub!=='pro'} onClick={togglePlayPause}>
                    {playing ? <PauseRounded sx={{color: "primary.main"}}/> : <PlayArrowRounded sx={{color: "primary.main"}}/>}
                </IconButton>
                <IconButton disabled={sub!=='premium' && sub!=='pro'} onClick={forwardAudio}>
                    <Forward10RoundedIcon sx={{color: "primary.main"}}/>
                </IconButton>
            </Stack>

            <Stack width="100%" direction="row" alignItems="center" spacing={1.3} minWidth="120px">
                <Typography fontSize={14} color="primary">{formatTime(progress)}</Typography>
                <Slider
                    size="small"
                    min={0}
                    max={duration}
                    value={progress}
                    onChange={changeAudioProgress}
                    disabled={sub !== 'premium'}
                />
                <Typography fontSize={14} color="primary">{formatTime(duration)}</Typography>
            </Stack>

            <Button
                size="small"
                aria-label="change playback speed"
                onClick={(e)=>setSpeedAnchorEl(e.currentTarget)}
                sx={{ml: mobile ? "2px" : "14px"}}
                disabled={sub !== 'premium'}
            >
                <Typography fontWeight="bold" color="primary.main" fontSize={mobile ? 14 : 18} 
                sx={{px: 0.8, py: (speed === 1 || speed === 2) ? 0.2 : ((speed === 1.5 || speed === 0.4) ? 0.5 : 0.8)}}>
                    {speed}x
                </Typography>
            </Button>
            <Popover 
                id={speedId}
                open={speedPopOpen}
                anchorEl={speedAnchorEl}
                onClose={() => setSpeedAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box>
                    <List sx={{p:0}}>
                        <ListItemButton key={"0.5"} onClick={() => {setSpeed(0.5);setSpeedAnchorEl(null);}}>
                            <Typography sx={{color: speed === 0.5 ? "primary.main" : "inherit", 
                            fontWeight: speed === 0.5 ? "bold" : "normal"}} >0.5x</Typography>
                        </ListItemButton>
                        <ListItemButton key={"0.75"} onClick={() => {setSpeed(0.75);setSpeedAnchorEl(null);}}>
                            <Typography sx={{color: speed === 0.75 ? "primary.main" : "inherit", 
                            fontWeight: speed === 0.75 ? "bold" : "normal"}} >0.75x</Typography>
                        </ListItemButton>
                        <ListItemButton key={"1"} onClick={() => {setSpeed(1);setSpeedAnchorEl(null);}}>
                            <Typography sx={{color: speed === 1 ? "primary.main" : "inherit", 
                            fontWeight: speed === 1 ? "bold" : "normal"}}  >1x</Typography>
                        </ListItemButton>
                        <ListItemButton key={"1.25"} onClick={() => {setSpeed(1.25);setSpeedAnchorEl(null)}}>
                            <Typography sx={{color: speed === 1.25 ? "primary.main" : "inherit", 
                            fontWeight: speed === 1.25 ? "bold" : "normal"}} >1.25x</Typography>
                        </ListItemButton>
                        <ListItemButton key={"1.5"} onClick={() => {setSpeed(1.5);setSpeedAnchorEl(null)}}>
                            <Typography sx={{color: speed === 1.5 ? "primary.main" : "inherit", 
                            fontWeight: speed === 1.5 ? "bold" : "normal"}} >1.5x</Typography>
                        </ListItemButton>
                        <ListItemButton key={"1.75"} onClick={() => {setSpeed(1.75);setSpeedAnchorEl(null)}}>
                            <Typography sx={{color: speed === 1.75 ? "primary.main" : "inherit", 
                            fontWeight: speed === 1.75 ? "bold" : "normal"}} >1.75x</Typography>
                        </ListItemButton>
                        <ListItemButton key={"2"} onClick={() => {setSpeed(2);setSpeedAnchorEl(null)}}>
                            <Typography sx={{color: speed === 2 ? "primary.main" : "inherit", 
                            fontWeight: speed === 2 ? "bold" : "normal"}} >2x</Typography>
                        </ListItemButton>
                    </List>
                </Box>
            </Popover>

            <Stack direction="row" alignItems="center" width="100%" minWidth="80px" maxWidth="130px">
                <IconButton disabled={sub!=='premium' && sub!=='pro'} onClick={toggleMute}>
                {muted ? (
                    <VolumeOffRounded sx={{color: "primary.main"}}/>
                ) : volume < 0.5 ? (
                    <VolumeDownRounded sx={{color: "primary.main"}}/>
                ) : (
                    <VolumeUpRounded sx={{color: "primary.main"}}/>
                )}
                </IconButton>
                <Slider
                    size="small"
                    min={0}
                    max={1}
                    step={0.01}
                    value={muted ? 0 : volume}
                    onChange={changeVolume}
                    disabled={sub!=='premium' && sub!=='pro'}
                />
            </Stack>
        </Stack> : 
        <Stack>
            <Stack width="100%" direction="row" alignItems="center" spacing={1.4} minWidth="120px" px={2}>
                <Typography fontSize={14} mr={0.5} color="primary">{formatTime(progress)}</Typography>
                <Slider
                    size="small"
                    min={0}
                    max={duration}
                    value={progress}
                    onChange={changeAudioProgress}
                    disabled={sub!=='premium' && sub!=='pro'}
                />
                <Typography fontSize={14} color="primary">{formatTime(duration)}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <IconButton disabled={sub!=='premium' && sub!=='pro'} onClick={rewindAudio}>
                        <Replay10RoundedIcon sx={{color: "primary.main"}}/>
                    </IconButton>
                    <IconButton disabled={sub!=='premium' && sub!=='pro'} onClick={togglePlayPause}>
                        {playing ? <PauseRounded sx={{color: "primary.main"}}/> : <PlayArrowRounded sx={{color: "primary.main"}}/>}
                    </IconButton>
                    <IconButton disabled={sub!=='premium' && sub!=='pro'} onClick={forwardAudio}>
                        <Forward10RoundedIcon sx={{color: "primary.main"}}/>
                    </IconButton>
                </Stack>

                <Button
                    size="small"
                    aria-label="change playback speed"
                    onClick={(e)=>setSpeedAnchorEl(e.currentTarget)}
                    sx={{ml: mobile ? "2px" : "14px"}}
                    disabled={sub !== 'premium'}
                >
                    <Typography fontWeight="bold" color="primary.main" fontSize={mobile ? 14 : 18} 
                    sx={{px: 0.8, py: (speed === 1 || speed === 2) ? 0.2 : ((speed === 1.5 || speed === 0.4) ? 0.5 : 0.8)}}>
                        {speed}x
                    </Typography>
                </Button>
                <Popover 
                    id={speedId}
                    open={speedPopOpen}
                    anchorEl={speedAnchorEl}
                    onClose={() => setSpeedAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Box>
                        <List sx={{p:0}}>
                            <ListItemButton key={"0.5"} onClick={() => {setSpeed(0.5);setSpeedAnchorEl(null);}}>
                                <Typography sx={{color: speed === 0.5 ? "primary.main" : "inherit", 
                                fontWeight: speed === 0.5 ? "bold" : "normal"}} >0.5x</Typography>
                            </ListItemButton>
                            <ListItemButton key={"0.75"} onClick={() => {setSpeed(0.75);setSpeedAnchorEl(null);}}>
                                <Typography sx={{color: speed === 0.75 ? "primary.main" : "inherit", 
                                fontWeight: speed === 0.75 ? "bold" : "normal"}} >0.75x</Typography>
                            </ListItemButton>
                            <ListItemButton key={"1"} onClick={() => {setSpeed(1);setSpeedAnchorEl(null);}}>
                                <Typography sx={{color: speed === 1 ? "primary.main" : "inherit", 
                                fontWeight: speed === 1 ? "bold" : "normal"}}  >1x</Typography>
                            </ListItemButton>
                            <ListItemButton key={"1.25"} onClick={() => {setSpeed(1.25);setSpeedAnchorEl(null)}}>
                                <Typography sx={{color: speed === 1.25 ? "primary.main" : "inherit", 
                                fontWeight: speed === 1.25 ? "bold" : "normal"}} >1.25x</Typography>
                            </ListItemButton>
                            <ListItemButton key={"1.5"} onClick={() => {setSpeed(1.5);setSpeedAnchorEl(null)}}>
                                <Typography sx={{color: speed === 1.5 ? "primary.main" : "inherit", 
                                fontWeight: speed === 1.5 ? "bold" : "normal"}} >1.5x</Typography>
                            </ListItemButton>
                            <ListItemButton key={"1.75"} onClick={() => {setSpeed(1.75);setSpeedAnchorEl(null)}}>
                                <Typography sx={{color: speed === 1.75 ? "primary.main" : "inherit", 
                                fontWeight: speed === 1.75 ? "bold" : "normal"}} >1.75x</Typography>
                            </ListItemButton>
                            <ListItemButton key={"2"} onClick={() => {setSpeed(2);setSpeedAnchorEl(null)}}>
                                <Typography sx={{color: speed === 2 ? "primary.main" : "inherit", 
                                fontWeight: speed === 2 ? "bold" : "normal"}} >2x</Typography>
                            </ListItemButton>
                        </List>
                    </Box>
                </Popover>
                {/*volume control */}
                <Stack direction="row" alignItems="center" width="100%" minWidth="70px" maxWidth="120px">
                    <IconButton disabled={sub!=='premium' && sub!=='pro'} onClick={toggleMute}>
                    {muted ? (
                        <VolumeOffRounded sx={{color: "primary.main"}}/>
                    ) : volume < 0.5 ? (
                        <VolumeDownRounded sx={{color: "primary.main"}}/>
                    ) : (
                        <VolumeUpRounded sx={{color: "primary.main"}}/>
                    )}
                    </IconButton>
                    <Slider
                        size="small"
                        min={0}
                        max={1}
                        step={0.01}
                        value={muted ? 0 : volume}
                        onChange={changeVolume}
                        disabled={localStorage.getItem("sub") !=='pro' && localStorage.getItem("sub") !=='premium'}
                    />
                </Stack>
            </Stack>
        </Stack>
        }
        </Tooltip>
        <audio ref={audioRef} src={src} preload="metadata" type="audio/ogg"/>
    </Box>
  );
}

export default AudioPlayer;