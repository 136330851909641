import * as React from 'react';
import { AppBar, Box, CssBaseline, IconButton, Stack, Toolbar, Typography, Menu, MenuItem, Fab, Tooltip,
  useMediaQuery, Drawer, List, ListItem, ListItemText, ListItemButton, Popover } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import logo from '../assets/logo.svg';
import { useNavigate } from 'react-router-dom';
import { Person as PersonIcon } from '@mui/icons-material';
import { Link as ReactRouterLink } from 'react-router-dom';
import axios from 'axios';
import config from '../../config.json';
import SearchBar from './Searchbar';

export default function ResponsiveDrawer() {
  const navigate = useNavigate();
  const backendUrl = config.backendUrl;
  //const contextData = useRootContext()
  const [searchText, setSearchText] = React.useState('');
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const userStyle = {
    color: 'common.white',
    bgcolor: '#33365b',
    boxShadow: 0,
    '&:hover': {
      bgcolor: '#232647',
    },
    width: '40px',
    height: '40px',
  }


  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleClear = () => {
    setSearchText('');
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logoutHandler = async () => {
    try {
        await axios.post(backendUrl+"/api/auth/logout").then(res => fullyLogout(res.data));
    } catch (err) {
        console.log(err)
    }
  //localStorage.removeItem("authToken");
    
  };

  const bookmarkHandler = async () => {
    navigate('/bookmarks');
    setAnchorElUser(null);
  }

  const userProfileHandler = async () => {
    navigate("/profile");
    setAnchorElUser(null);
  }

  const fullyLogout = (msg) => {
  if (msg === 'OK') {
      window.location.reload();
      localStorage.removeItem("sub");
      //setLoggedIn(false);
  }
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


    const handlePopoverClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'ai-popover' : undefined;

    const drawer = (
        <List sx={{ letterSpacing: '0.5px' }}>
          {localStorage.getItem('sub') ?
            <>
                <ListItem key={"Summaries"} disablePadding>
                    <ListItemButton onClick={()=>{navigate("/search/query=2000,2011,2013,2014,2015,2016,2017,2018,2019,2020,2021,2022&subject=&publisher=&edition=&year=&page=1&sortBy=Default"); setMobileOpen(false);}}>
                    <ListItemText primary={"Summaries"} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={"AI Flashcards"} disablePadding>
                    <ListItemButton onClick={()=>{navigate("/ai/flashcards"); setMobileOpen(false);}}>
                    <ListItemText primary={"AI Flashcards"} />
                    </ListItemButton>
                </ListItem>
                <ListItem divider key={"AI Mock Exam"} disablePadding>
                    <ListItemButton onClick={()=>{navigate("/ai/quiz"); setMobileOpen(false);}}>
                    <ListItemText primary={"AI Mock Exam"} />
                    </ListItemButton>
                </ListItem>
                <ListItem divider key={"Pricing"} disablePadding>
                    <ListItemButton onClick={()=>{navigate("/pricing"); setMobileOpen(false);}}>
                    <ListItemText primary={"Pricing"} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={"Logout"} disablePadding>
                    <ListItemButton onClick={logoutHandler}>
                        <ListItemText primary={"Logout"} />
                    </ListItemButton>
                </ListItem>
            </>
            :
            <>
              <ListItem divider key={"Summaries"} disablePadding>
                  <ListItemButton onClick={()=>{navigate("/search/query=2000,2011,2013,2014,2015,2016,2017,2018,2019,2020,2021,2022&subject=&publisher=&edition=&year=&page=1&sortBy=Default"); setMobileOpen(false);}}>
                  <ListItemText primary={"Summaries"} />
                  </ListItemButton>
              </ListItem>
              <ListItem divider key={"Pricing"} disablePadding>
                <ListItemButton onClick={()=>{navigate("/pricing"); setMobileOpen(false);}}>
                  <ListItemText primary={"Pricing"} />
                </ListItemButton>
              </ListItem>
              <ListItem key={"Sign Up"} disablePadding>
                <ListItemButton onClick={()=>{navigate("/signup"); setMobileOpen(false);}}>
                  <ListItemText primary={"Sign Up"} />
                </ListItemButton>
              </ListItem>
              <ListItem key={"Sign in"} disablePadding>
                <ListItemButton onClick={()=>{navigate("/login"); setMobileOpen(false);}}>
                  <ListItemText primary={"Sign in"} />
                </ListItemButton>
              </ListItem>
            </>
          }
        </List>
    );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        enableColorOnDark
        sx={{
          height: isMobile ? '116px' : '64px',
          display: 'flex',
          justifyContent: 'center',
          //boxShadow: 'none',
          bgcolor: "#02152b"
        }}
      >
            <Toolbar>
            {isTablet && !isMobile && <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle}
              sx={{ mr: 1, display: { md: 'none' } }}
            >
                 <MenuIcon />
            </IconButton>}
            {!isMobile ? <Stack direction="row" alignItems="center" spacing={3} width="100%">
              <ReactRouterLink to="" style={{textDecoration: 'none'}}>
                <Stack direction="row" alignItems="center">
                  <img src={logo} alt="logo" width={isMobile ? "36" : "42"} height={isMobile ? "36" : "42"} />
                  <Box p={1} borderRadius={2} variant={isMobile ? "h6" : "h5" } component={Typography} sx={{fontWeight: 500, color: "white"}}>
                    EZread
                  </Box>
                </Stack>
              </ReactRouterLink>
              {!isTablet && <ReactRouterLink to={`/search/query=2000,2011,2013,2014,2015,2016,2017,2018,2019,2020,2021,2022&subject=&publisher=&edition=&year=&page=1&sortBy=Default`} style={{textDecoration: 'none'}}>
                <Box p={1} borderRadius={2} component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {color: primaryColor}}}>
                  Summaries
                </Box>
              </ReactRouterLink>}
              {!isTablet && <>
                <Typography p={1} borderRadius={2} onClick={handlePopoverClick}
                sx={{width: '116px', whiteSpace: 'nowrap', fontWeight: 500, color: "white", ":hover": {color: primaryColor, cursor: "pointer"}}}>
                  AI Tools
                </Typography>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <List sx={{p: 0}}>
                        <ListItem disablePadding>
                            <ListItemButton onClick={()=>{navigate("/ai/flashcards"); handlePopoverClose();}}>
                            <Typography>Flashcards</Typography>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={()=>{navigate("/ai/quiz"); handlePopoverClose();}}>
                            <Typography>Mock Exam</Typography>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Popover>
              </>}
              {!isTablet && <ReactRouterLink to={`/pricing`} style={{textDecoration: 'none'}}>
                <Box p={1} borderRadius={2} component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {color: primaryColor}}}>
                  Pricing
                </Box>
              </ReactRouterLink>}
              <SearchBar
                searchText={searchText}
                setSearchText={setSearchText}
                handleClear={handleClear}
              />
              { localStorage.getItem("sub") && 
                <Box sx={{ flexGrow: 0, ml:"0.4vw", mr:"1.8vw" }}>
                    <Tooltip title="Account Settings">
                    <Fab sx={userStyle} onClick={(event) => handleOpenUserMenu(event)}>
                      <PersonIcon/>
                    </Fab>
                    </Tooltip>
                    <Menu
                    sx={{ mt: '45px', '& .MuiMenu-paper':{color:"#fff", bgcolor: '#1e3957', border: '1px solid gray', boxShadow: 'none'} }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                    >
                     <MenuItem disablePadding key="Profile" onClick={userProfileHandler}>
                        <Typography textAlign="center">Profile</Typography>
                    </MenuItem>
                    <MenuItem key="Bookmarks" onClick={bookmarkHandler}>
                        <Typography textAlign="center">Bookmarks</Typography>
                    </MenuItem>
                    <MenuItem key="Logout" onClick={logoutHandler}>
                        <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                    </Menu>
                </Box> }
              {!localStorage.getItem("sub") && !isMobile && <ReactRouterLink to="login" style={{textDecoration: 'none'}}>
                <Box noWrap={true} p={1} borderRadius={2} component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {color: primaryColor}}}>
                  Log in
                </Box>
              </ReactRouterLink>}
              {!localStorage.getItem("sub") && !isMobile &&
              <ReactRouterLink to={`/signup`} style={{textDecoration: 'none' }}>
                <Box noWrap={true} p={1} borderRadius={2} component={Typography} sx={{fontWeight: 500, color: "black", bgcolor: 'gold', px: 2, "&:hover": {opacity: 0.8} }}>
                  Sign up
                </Box>
              </ReactRouterLink>
              }
            </Stack> :
            <Stack width="100%" spacing={1}>
              <Stack direction="row" alignItems="center">
                <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle}
                    sx={{ mr: 1, display: { sm: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>
                <ReactRouterLink to="" style={{textDecoration: 'none'}}>
                  <Stack direction="row" alignItems="center">
                    <img src={logo} alt="logo" width={isMobile ? "38" : "42"} height={isMobile ? "38" : "42"} />
                    <Box p={1} borderRadius={2} variant={isMobile ? "h6" : "h5" } component={Typography} sx={{fontWeight: 500, color: "white"}}>
                      EZread
                    </Box>
                  </Stack>
                </ReactRouterLink>
                <Box sx={{ flexGrow: 1 }} />
                { localStorage.getItem("sub") ?
                <Box sx={{ flexGrow: 0, ml:"0.4vw", mr:"1.8vw" }}>
                    <Tooltip title="Account Settings">
                    <Fab sx={userStyle} onClick={(event) => handleOpenUserMenu(event)}>
                      <PersonIcon/>
                    </Fab>
                    </Tooltip>
                    <Menu
                    sx={{ mt: '45px', '& .MuiMenu-paper':{color:"#fff", bgcolor: '#1e3957', border: '1px solid gray', boxShadow: 'none'} }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                    >
                     <MenuItem key="Profile" onClick={userProfileHandler}>
                        <Typography textAlign="center" fontSize={isMobile ? 14 : 16}>Profile</Typography>
                    </MenuItem>
                    <MenuItem key="Bookmarks" onClick={bookmarkHandler}>
                        <Typography textAlign="center" fontSize={isMobile ? 14 : 16}>Bookmarks</Typography>
                    </MenuItem>
                    <MenuItem key="Logout" onClick={logoutHandler}>
                        <Typography textAlign="center" fontSize={isMobile ? 14 : 16}>Logout</Typography>
                    </MenuItem>
                    </Menu>
                </Box> :
                <ReactRouterLink to={`/signup`} style={{textDecoration: 'none' }}>
                  <Box noWrap={true} p={1} borderRadius={2} component={Typography} sx={{fontWeight: 500, color: "black", bgcolor: 'gold', px: 1.75, fontSize: 15}}>
                    Free Trial
                  </Box>
                </ReactRouterLink>}
              </Stack>
              <SearchBar
                searchText={searchText}
                setSearchText={setSearchText}
                handleClear={handleClear}
              />
            </Stack>
            }
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
