import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box, Button, Stack, Collapse, Alert, Divider, Typography, IconButton, TextField, Dialog, DialogTitle, 
  DialogContent, DialogContentText, DialogActions, Menu, MenuItem, ListItemText,
  ListItemSecondaryAction, Tooltip, Radio, RadioGroup, FormControlLabel,
  FormControl, FormHelperText, useMediaQuery, Link
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import LoopRoundedIcon from '@mui/icons-material/LoopRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import configuration from '../../config.json';

const PracticeQuiz = () => {
  const backendUrl = configuration.backendUrl;
  const navigate = useNavigate();
  const mobile = useMediaQuery('(max-width:600px)');
  const [error, setError] = useState('');
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [curQuestionNum, setCurQuestionNum] = useState(1);
  const [front, setFront] = useState(true);
  const [file, setFile] = useState(null);
  const [text, setText] = useState('');
  const [notes, setNotes] = useState('');
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [quizName, setQuizName] = useState('');
  const [modalError, setModalError] = useState('');
  const [modalSuccess, setModalSuccess] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [quizzes, setQuizzes] = useState([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [quizToDelete, setQuizToDelete] = useState(null);
  const [quizToDeleteName, setQuizToDeleteName] = useState('');
  const [quizToLoadName, setQuizToLoadName] = useState('');
  const [quizToLoadId, setQuizToLoadId] = useState(null);
  const [value, setValue] = useState(null);
  const [helperText, setHelperText] = useState('');
  const [questionColor, setQuestionColor] = useState('inherit');

  // Fetch user's question quizzes
  const fetchQuizzes = async () => {
    try {
      const token = await axios.post(
        backendUrl + '/api/auth/refresh-token',
        {},
        { withCredentials: true }
      );
      if (token.data && token.data !== 'Unauthorized') {
        const config = {
          headers: {
            Authorization: `Bearer ${token.data}`,
          },
          withCredentials: true,
        };
        const response = await axios.get(
          backendUrl + '/api/ai/get-quizzes',
          config
        );
        setQuizzes(response.data);
      } else {
        setError('Unauthorized access. Please log in again.');
      }
    } catch (error) {
      console.error(error);
      setError('Error fetching quizzes. Please try again.');
    }
  };

  useEffect(() => {
    fetchQuizzes();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setNotes('');
  };

  const handleQuestions = async (e) => {
    e.preventDefault();
    if (!file && !notes) {
      setError('Please select a file or provide notes to generate questions.');
      return;
    }

    const token = await axios.post(
      backendUrl + '/api/auth/refresh-token',
      {},
      { withCredentials: true }
    );
    if (token.data && token.data !== 'Unauthorized') {
      setLoading(true);
      let formData;

      if (notes) {
        // When notes are provided, use a JSON payload
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.data}`,
          },
        };
        try {
          const { data } = await axios.post(
            backendUrl + '/api/ai/create-quiz',
            { notes: notes },
            config
          );
          setQuestions(data);
          setCurQuestionNum(1);
          setQuizToLoadName('');
        } catch (err) {
          //console.error(err);
          if (err?.response?.data?.error) {
            setError(err.response.data.error);
          } else if (err.message) {
            setError(err.message);
          } else setError('Error generating questions. Please try again.');
        } finally {
          window.scrollTo(0, 0);
          setLoading(false);
        }
      } else {
        formData = new FormData();
        formData.append('file', file);

        try {
          const config = {
            headers: {
              Authorization: `Bearer ${token.data}`,
            },
          };

          const { data } = await axios.post(
            backendUrl + '/api/ai/create-quiz',
            formData,
            config
          );
          setQuestions(data);
          setCurQuestionNum(1);
          setQuizToLoadName('');
        } catch (err) {
          //console.error(err);
          if (err?.response?.data?.error) {
            setError(err.response.data.error);
          } else if (err.message) {
            setError(err.message);
          } else setError('Error generating questions. Please try again.');
        } finally {
          window.scrollTo(0, 0);
          setLoading(false);
        }
      }
    } else {
      setError('Unauthorized access. Please log in again.');
    }
  };

  const handleQuestionNext = () => {
    if (curQuestionNum < questions.length) {
      setCurQuestionNum(curQuestionNum + 1);
      setFront(true);
      setHelperText('');
      setQuestionColor('inherit');
    }
  };

  const handleQuestionPrior = () => {
    if (curQuestionNum > 1) {
      setCurQuestionNum(curQuestionNum - 1);
      setFront(true);
      setHelperText('');
      setQuestionColor('inherit');
    }
  };

  const handleOpenSaveModal = () => {
    setOpenSaveModal(true); // Open modal
  };

  const handleCloseSaveModal = () => {
    setOpenSaveModal(false); // Close modal
  };

  const handleSaveQuiz = async () => {
    if (!quizName.trim()) {
      setModalError('Please enter a quiz name.');
      return;
    }

    try {
      const token = await axios.post(
        backendUrl + '/api/auth/refresh-token',
        {},
        { withCredentials: true }
      );
      if (token.data && token.data !== 'Unauthorized') {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.data}`,
          },
        };
        const response = await axios.post(
          backendUrl + '/api/ai/save-quiz',
          { quizName, questions },
          config
        );
        setModalSuccess('Quiz saved successfully!');
        // Fetch quizzes again to update the list
        fetchQuizzes();
        // Wait 2 seconds and close the modal
        setTimeout(() => {
          setOpenSaveModal(false);
          setModalSuccess('');
        }, 2000);
      } else {
        setModalError('Unauthorized access. Please log in again.');
      }
    } catch (error) {
      console.error(error);
      setModalError('Error saving quiz. Please try again.');
    }
  };

  const handleDeleteClick = (quizId, quizName) => {
    setQuizToDelete(quizId);
    setQuizToDeleteName(quizName);
    setDeleteConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!quizToDelete) return;

    try {
      const token = await axios.post(
        backendUrl + '/api/auth/refresh-token',
        {},
        { withCredentials: true }
      );
      if (token.data && token.data !== 'Unauthorized') {
        const config = {
          headers: {
            Authorization: `Bearer ${token.data}`,
          },
        };
        await axios.delete(
          backendUrl + `/api/ai/delete-quiz/${quizToDelete}`,
          config
        );
        // Update the quiz list after deletion
        fetchQuizzes();
        setDeleteConfirmOpen(false);
        setQuizToDelete(null);
        setQuizToDeleteName('');
        //if quizToDelete is the current quiz, reload page
        if (quizToDelete === quizToLoadId) {
            setQuestions([]);
            setNotes('');
            setFile(null);
            setCurQuestionNum(1);
            setQuizToLoadName('');
            setQuizToLoadId(null);
            setHelperText('');
            setQuestionColor('inherit');
            window.location.reload();
        }
      } else {
        setError('Unauthorized access. Please log in again.');
      }
    } catch (error) {
      console.error(error);
      setError('Error deleting quiz. Please try again.');
    }
  };

  const handleCancelDelete = () => {
    setDeleteConfirmOpen(false);
    setQuizToDelete(null);
    setQuizToDeleteName('');
  };

  // Handle menu open/close
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLoadQuiz = async (quizId, quizName) => {
    handleMenuClose(); // Close the menu
    try {
      const token = await axios.post(
        backendUrl + '/api/auth/refresh-token',
        {},
        { withCredentials: true }
      );
      if (token.data && token.data !== 'Unauthorized') {
        const config = {
          headers: {
            Authorization: `Bearer ${token.data}`,
          },
        };
        const response = await axios.get(
          backendUrl + `/api/ai/get-quiz/${quizId}`,
          config
        );
        console.log(response.data);
        setQuestions(response.data.quiz);
        setCurQuestionNum(1);
        setQuizToLoadName(quizName);
        setQuizToLoadId(quizId);
        setFront(true);
        setFile(null);
        setNotes('');
      } else {
        setError('Unauthorized access. Please log in again.');
      }
    } catch (error) {
      //console.error(error);
      setError('Error loading quiz. Please try again.');
    }
  };

  // UseEffect for error messages
  useEffect(() => {
    if (error) {
      setLoading(false);
      setTimeout(() => {
        setError('');
      }, 5000);
    } else if (modalError) {
      setTimeout(() => {
        setModalError('');
      }, 5000);
    }
  }, [error, modalError]);

  // UseEffect for front text content
  useEffect(() => {
    if (front && questions.length > 0) {
      setText(questions[curQuestionNum - 1].front);
    } else if (!front && questions.length > 0) {
      setText(questions[curQuestionNum - 1].back);
    }
  }, [front, questions, curQuestionNum]);

  const handleChoiceChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (value === questions[curQuestionNum-1].answer) {
      setHelperText('You got it!');
      setQuestionColor('#50cc20');
    } else if (value) {
      setHelperText('Sorry, wrong answer!');
      setQuestionColor('#cc0808');
    } else {
      setHelperText('Please select an option.');
      setQuestionColor('inherit');
    }
  };

  return (
    <Box px="4vw" py="2vh">
      <Collapse in={error}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      </Collapse>
      {(!localStorage.getItem("sub") || !localStorage.getItem("sub") === "none") && <Collapse in={true}>
        <Alert severity="warning" sx={{ mb: 2 }}>
          Please <Link underline="hover" onClick={() => {navigate('/pricing');}}
          sx={{ color: 'inherit', '&:hover': { cursor: 'pointer' } }}> <b>subscribe</b> </Link> to access this feature.
        </Alert>
      </Collapse> }
      <Stack direction="row" alignItems="center">
        <Typography fontWeight="bold" variant={mobile ? "h5" : "h4"} mt={2} mb={mobile ? 1 : 2}>
          Mock Exam
        </Typography>
        <div>
          {quizzes.length > 0 && 
            <Tooltip title="Saved Quizzes" placement='right'>
                <IconButton onClick={handleMenuOpen}>
                    <MoreVertIcon />
                </IconButton>
            </Tooltip>}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {quizzes.map((quiz) => (
              <MenuItem
                key={quiz._id}
                onClick={() => handleLoadQuiz(quiz._id, quiz.name)}
              >
                <ListItemText primary={quiz.name} />
                <Box width={quiz.name ? quiz.name.length*2 + 20 : 40}/>
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering onClick on MenuItem
                      handleDeleteClick(quiz._id, quiz.name);
                    }}
                  >
                    <DeleteRoundedIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </MenuItem>
            ))}
          </Menu>
        </div>
      </Stack>
      <Divider variant="fullWidth" color="#374e69" />
      {questions && questions.length < 1 && (
        <>
          <TextField
            label="Paste notes here..."
            size="small"
            multiline
            variant="outlined"
            value={notes}
            fullWidth={notes.length > 0}
            sx={{ mt: 2, mb: 1 }}
            onChange={(e) => setNotes(e.target.value)}
          />
          <Typography mb={1} mt={0.5}>
            OR
          </Typography>
          <input
            type="file"
            accept=".docx, .pptx, .xlsx, .pdf"
            onChange={handleFileChange}
            style={{ marginBottom: '1rem' }}
          />
          <br />
          <Button
            onClick={handleQuestions}
            variant="outlined"
            disabled={loading || (!file && !notes)}
            sx={{ mr: 10 }}
          >
            {loading ? 'Generating...' : 'Generate Practice Quiz'}
          </Button>
        </>
      )}

      {questions.length > 0 && (
        <Stack alignItems="center" mt={mobile ? 2 : 4}>
          <Typography variant={mobile ? "h6" : "h5"} mb={2} fontWeight={700}>
              {quizToLoadName}
          </Typography>
          <form onSubmit={handleSubmit}>
            <FormControl sx={{color: questionColor, maxWidth: 300}}>
              <Typography fontSize={mobile ? 18 : 24} fontWeight="500" mb={0.75}>{questions[curQuestionNum-1].question}</Typography>
              <RadioGroup
                aria-labelledby="radio-buttons-group"
                name="radio-buttons"
                value={value}
                onChange={handleChoiceChange}
              >
                <FormControlLabel sx={{'.MuiFormControlLabel-label': {fontSize: mobile ? 14 : 16}}} value={questions[curQuestionNum-1].choices[0]} control={<Radio />} label={questions[curQuestionNum-1].choices[0]} />
                <FormControlLabel sx={{'.MuiFormControlLabel-label': {fontSize: mobile ? 14 : 16}}} value={questions[curQuestionNum-1].choices[1]} control={<Radio />} label={questions[curQuestionNum-1].choices[1]} />
                <FormControlLabel sx={{'.MuiFormControlLabel-label': {fontSize: mobile ? 14 : 16}}} value={questions[curQuestionNum-1].choices[2]} control={<Radio />} label={questions[curQuestionNum-1].choices[2]} />
                <FormControlLabel sx={{'.MuiFormControlLabel-label': {fontSize: mobile ? 14 : 16}}} value={questions[curQuestionNum-1].choices[3]} control={<Radio />} label={questions[curQuestionNum-1].choices[3]} />
              </RadioGroup>
              <FormHelperText>{helperText}</FormHelperText>
              <Button sx={{ mt: 1.25, width: mobile ? 140 : 160 }} type="submit" variant="outlined" size={mobile ? "small" : "medium"}>
                Check Answer
              </Button>
            </FormControl>
          </form>
          
          <Stack direction="row" spacing={2} alignItems="center" mt={3}>
            <Button
              variant="outlined"
              startIcon={<NavigateBeforeRoundedIcon />}
              onClick={handleQuestionPrior}
              disabled={curQuestionNum === 1}
              size={mobile ? "small" : "medium"}
            >
              {mobile ? "" : "Back"}
            </Button>
            <Typography fontWeight="bold" fontSize={mobile ? 14 : 16}>
              {' '}
              {curQuestionNum} / {questions.length}
            </Typography>
            <Button
              variant="outlined"
              endIcon={<NavigateNextRoundedIcon />}
              disabled={curQuestionNum === questions.length}
              onClick={handleQuestionNext}
              size={mobile ? "small" : "medium"}
            >
              {mobile ? "" : "Next"}
            </Button>
          </Stack>
          <Stack direction={mobile ? "column" : "row"} spacing={mobile ? 1.25 : 2} alignItems="center" mt={mobile ? 3.5 : 5}>
            <Button
              variant="contained"
              disableElevation={true}
              endIcon={<SaveRoundedIcon />}
              onClick={handleOpenSaveModal}
              disabled={quizToLoadName !== ''}
              size={mobile ? "small" : "medium"}
            >
              Save Quiz
            </Button>
            <Button
              color="info"
              variant="contained"
              disableElevation={true}
              endIcon={<LoopRoundedIcon />}
              onClick={() => {
                setQuestions([]);
                setNotes('');
                setFile(null);
                setCurQuestionNum(1);
              }}
              size={mobile ? "small" : "medium"}
            >
              New Quiz
            </Button>
          </Stack>
        </Stack>
      )}
      
      {/* Dialog (Modal) for saving the quiz */}
      <Dialog open={openSaveModal} onClose={handleCloseSaveModal}>
        <DialogTitle>Save Quiz</DialogTitle>
        <DialogContent>
          <Collapse in={modalError.length > 0}>
            <Alert severity="error" sx={{ mb: 2 }}>
              {modalError}
            </Alert>
          </Collapse>
          <Collapse in={modalSuccess.length > 0}>
            <Alert severity="success" sx={{ mb: 2 }}>
              {modalSuccess}
            </Alert>
          </Collapse>
          <TextField
            autoFocus
            margin="dense"
            label="Quiz Name"
            type="text"
            fullWidth
            variant="standard"
            value={quizName}
            onChange={(e) => setQuizName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSaveModal}>Cancel</Button>
          <Button
            onClick={handleSaveQuiz}
            variant="contained"
            disableElevation={true}
            disabled={!quizName && modalSuccess.length > 0}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {/* Confirmation Dialog for Deleting a Quiz */}
      <Dialog open={deleteConfirmOpen} onClose={handleCancelDelete}>
        <DialogTitle>Delete Practice Quiz</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the quiz "{quizToDeleteName}"? This
            action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PracticeQuiz;
