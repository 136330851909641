import { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { Viewer } from '@react-pdf-viewer/core';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core';
import {
    Avatar,
    Box,
    Alert,
    Collapse,
    Typography,
    Button,
    TextField,
    useMediaQuery,
    Stack,
    MenuItem,
    Tooltip,
    InputAdornment,
    Card,
    Link,
} from '@mui/material';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import LoadingSpinner from '../common/LoadingSpinner';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import configuration from '../../config.json';
import Markdown from 'react-markdown';
import PersonIcon from '@mui/icons-material/Person';
import { SocketContext } from '../../SocketContext';
import logo from '../assets/logo.svg';
import AudioPlayer from '../common/AudioPlayer';

// Adjusted code starts here

const SummaryScreen = () => {
    const socket = useContext(SocketContext);
    const navigate = useNavigate();
    const location = useLocation();
    const { chapterId } = useParams(); // Use chapterId from useParams
    const isNotMobile = useMediaQuery('(min-width:600px)');
    const backendUrl = configuration.backendUrl;
    const [chapters, setChapters] = useState(location?.state?.chapters || []);
    const [error, setError] = useState('');
    const [pageLoading, setPageLoading] = useState(true);
    const [pdfFile, setPdfFile] = useState(null);
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const zoomPluginInstance = zoomPlugin();
    const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
    const { CurrentPageInput, GoToFirstPageButton, GoToLastPageButton, GoToNextPageButton, GoToPreviousPage } =
        pageNavigationPluginInstance;
    const [pdfWidth, setPdfWidth] = useState('60%');
    const [unlocked, setUnlocked] = useState(true);
    const [subscribed, setSubscribed] = useState(true);
    const [upgrade, setUpgrade] = useState(true);
    const [audioSrc, setAudioSrc] = useState('');
    const [audioLoading, setAudioLoading] = useState(true);
    const [rating, setRating] = useState(0);
    const [threadId, setThreadId] = useState('');
    const [userMessages, setUserMessages] = useState([]);
    const [systemMessages, setSystemMessages] = useState([]);
    const [id, setId] = useState('id');
    const [authConfig, setAuthConfig] = useState({});
    const [question, setQuestion] = useState('');
    const [chatLoading, setChatLoading] = useState(false);
    const [assistantId, setAssistantId] = useState('');
    const [accessToken, setAccessToken] = useState('');
    const messagesContainerRef = useRef(null);
    const [chatError, setChatError] = useState('');
    const [duration, setDuration] = useState(0);
    const [hasSub, setHasSub] = useState(false);
    const [needsUpgrade, setNeedsUpgrade] = useState(false);

    const config = {
        responseType: 'arraybuffer',
        responseEncoding: 'binary',
    };

    useEffect(() => {
        // Listen for the 'message' event
        socket.on('chatPipe', (data) => {
            console.log(data);
            setChatLoading(false);
            const newMessage = data.message; // This is the streamed response
            setSystemMessages((prev) => [...prev.slice(0, -1), prev[prev.length - 1] + newMessage]);
            setQuestion('');
        });

        // Clean up the effect by removing the listener
        return () => {
            socket.off('chatPipe');
        };
    }, [socket]);

    // useEffect for location
    useEffect(() => {
        const fetchData = async () => {
            if (chapters.length === 0) {
                try {
                    const res = await axios.get(backendUrl + `/api/chapters/${chapterId}/textbook`);
                    setChapters(res.data.chapters);
                } catch (error) {
                    console.error(error);
                }
            }
        };
        const getAccessToken = async () => {
            try {
                const token = await axios.post(backendUrl + '/api/auth/refresh-token', {}, { withCredentials: true });
                if (token.data && token.data !== 'Unauthorized') {
                    setAccessToken(token.data);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
        getAccessToken();
    }, [chapterId]); // Use chapterId as dependency

    // useEffect for current chapter
    useEffect(() => {
        const unlockChapter = async () => {
            setPageLoading(true);
            setAudioLoading(true);
            const chap = await axios.get(backendUrl + `/api/chapters/${chapterId}/comments`);
            // Compute current chapter index
            // const chapterNumber = chap.data.chapterNumber - 1;
            // setCurrChap(chapterNumber); // Remove this line

            if (chap.data.chapterNumber > 1) {
                let authToken = accessToken;
                if (!accessToken) {
                    const token = await axios.post(backendUrl + '/api/auth/refresh-token', {}, { withCredentials: true });
                    if (token.data && token.data !== 'Unauthorized') {
                        setAccessToken(token.data);
                        authToken = token.data;
                    }
                }
                if (authToken) {
                    //console.log('sub:', localStorage.getItem("sub"));
                    const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${authToken}` } };
                    const {data} = await axios.get(backendUrl+`/api/auth/user`, config);
                    if (localStorage.getItem("sub") === "premium" || localStorage.getItem("sub") === "pro") {
                        setUnlocked(true);
                    } else if (localStorage.getItem("sub") === "basic") {
                        console.log('data', data.unlockedBooks[0])
                        if (data.unlockedBooks[0]) {
                            console.log('data chap', chap.data)
                            if (data.unlockedBooks[0] !== chap.data.textbook) {
                                setUnlocked(false);
                                setPageLoading(true);
                                setSubscribed(true);
                                setHasSub(true);
                                setUpgrade(true);
                                setNeedsUpgrade(true);
                                return
                            } else {
                                setUnlocked(true);
                                setSubscribed(true);
                                setHasSub(true);
                                setUpgrade(false);
                                setNeedsUpgrade(false);
                            }
                        } else {
                            setUnlocked(false);
                            setPageLoading(true);
                            setSubscribed(false);
                            setHasSub(false);
                            setUpgrade(false);
                            setNeedsUpgrade(false);
                            return
                        }
                    } else {
                        setUnlocked(false);
                        setPageLoading(true);
                        setSubscribed(true);
                        setHasSub(true);
                        setUpgrade(true);
                        setNeedsUpgrade(true);
                        return
                    }
                } else {
                    setAudioLoading(true);
                    setUnlocked(false);
                    return;
                }
            } else {
                setUnlocked(true);
                setSubscribed(true);
                setHasSub(true);
                setNeedsUpgrade(false);
                setUpgrade(false);
            }
            await axios
                .get(backendUrl + `/api/chapters/${chap.data.pdfFile}`, config)
                .then(async (res1) => {
                    var file = new Blob([res1.data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    setPdfFile(fileURL);
                });
            setPageLoading(false);
            const curConfig = { headers: { responseType: 'blob', 'Content-Type': 'application/json' } };
            try {
                const response = await fetch(`/api/audio/${chapterId}`, curConfig);
                if (response.ok) {
                    const durationHeader = response.headers.get('X-Duration');
                    if (durationHeader) {
                        console.log('Duration:', durationHeader);
                        // Set the duration from the header (assuming it's in seconds)
                        setDuration(parseFloat(durationHeader));
                    }
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    setAudioSrc(url);
                }
            } catch (error) {
                console.error('Error fetching audio:', error);
                setAudioLoading(false);
                setError('Error fetching audio');
            }
            setAudioLoading(false);
        };

        const createAssistant = async () => {
            const token = await axios.post(backendUrl + '/api/auth/refresh-token', {}, { withCredentials: true });
            if (token.data && token.data !== 'Unauthorized') {
                const config = {
                    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token.data}` },
                    withCredentials: true,
                };

                const res = await axios.post(backendUrl + `/api/chat/create-thread`, {}, config);
                setThreadId(res?.data?.threadId);

                const chap = await axios.get(backendUrl + `/api/chapters/${chapterId}/comments`);
                if (chap.data) {
                    if (chap.data.assistantId) {
                        setAssistantId(chap.data.assistantId);
                        return;
                    }
                    const { data } = await axios.post(
                        backendUrl + '/api/chat/create-summary-assistant',
                        { chapterId: chapterId, pdfFile: chap.data.pdfFile },
                        config
                    );
                    if (data) {
                        setAssistantId(data.assistantId);
                    }
                }
            }
        };

        unlockChapter();
        setSystemMessages([]);
        setUserMessages([]);
        setQuestion('');
        if (localStorage.getItem('sub') === 'premium') {
            createAssistant();
        }
    }, [chapterId, chapters]); // Use chapterId

    const handleAddTextbook = async (e) => {
        e.preventDefault();
        try {
            const token = await axios.post(backendUrl + '/api/auth/refresh-token', {}, { withCredentials: true });
            if (token.data && token.data !== 'Unauthorized') {
                const config = {
                    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token.data}` },
                    withCredentials: true,
                };
                const chap = await axios.get(backendUrl + `/api/chapters/${chapterId}/comments`);

                const textbook = chap.data.textbook;

                const res = await axios.post(backendUrl + '/api/sub/addTextbook', { textbook: textbook }, config);
                if (res.status === 200) {
                    setSubscribed(true);
                    window.location.reload();
                }
            }
        } catch (error) {
            console.log(error.response.data);
            if (error.response.data.error) {
                setError(error.response.data.error);
            } else if (error.message) {
                setError(error.message);
            } else {
                setError('Unknown error. Please try again.');
            }
        }
    };

    const handleZoom = (e) => {
        console.log(`Zoom to ${e.scale}`);
        var percentage = e.scale * 620;
        setPdfWidth(`${percentage}px`);
    };

    // Compute current chapter index
    const currChapIndex = chapters.indexOf(chapterId);

    const handleChap = (index, id) => {
        navigate(`/summary/${id}`, { state: { chapters: chapters } });
    };

    const handleChapNext = () => {
        if (currChapIndex < chapters.length - 1) {
            navigate(`/summary/${chapters[currChapIndex + 1]}`, { state: { chapters: chapters } });
        }
    };

    const handleChapPrior = () => {
        if (currChapIndex > 0) {
            navigate(`/summary/${chapters[currChapIndex - 1]}`, { state: { chapters: chapters } });
        }
    };

    useEffect(() => {
        if (error) {
            setAudioLoading(false);
            setTimeout(() => {
                setError('');
            }, 5000);
        }
    }, [error]);

    useEffect(() => {
        if (chatError) {
            setAudioLoading(false);
            setTimeout(() => {
                setChatError('');
            }, 5000);
        }
    }, [chatError]);

    const clearChat = async () => {
        try {
            const res = await axios.post(`/api/chat/create-thread`, {}, authConfig);
            setThreadId(res?.data?.threadId);
            setUserMessages([]);
            setSystemMessages([]);
            setId('id');
        } catch (err) {
            console.error(err);
            setError('Error clearing chat');
        }
    };

    const chatHandler = async (e) => {
        e.preventDefault();

        try {
            setChatLoading(true); // Start loading

            if (accessToken) {
                setUserMessages([...userMessages, question]);
                setSystemMessages([...systemMessages, '']);
                setQuestion('');
                // Scroll to the bottom after a message is submitted
                setTimeout(() => {
                    scrollToBottom();
                }, 500);

                // Fetch using POST and handle the stream manually
                const response = await fetch(`${backendUrl}/api/chat/message`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify({
                        content: question,
                        threadId: threadId,
                        assistantId: assistantId,
                    }),
                });

                const reader = response.body.getReader(); // Read the streamed response

                const decoder = new TextDecoder('utf-8');
                let result = '';
                let done = false;

                while (!done) {
                    const { value, done: readerDone } = await reader.read(); // Read each chunk
                    done = readerDone;

                    const chunk = decoder.decode(value, { stream: true });
                    result += chunk;

                    // Process each chunk of the stream (for real-time updates)
                    setSystemMessages((prev) => [...prev.slice(0, -1), prev[prev.length - 1] + chunk]);
                    setChatLoading(false); // Stop loading after the stream ends
                }
            } else {
                setChatLoading(false);
                setChatError('You are not authorized to use this feature. Please login.');
                return;
            }
        } catch (err) {
            setChatLoading(false);
            console.error(err);
            setChatError('Something went wrong. Please try again later.');
        }
    };

    const scrollToBottom = () => {
        messagesContainerRef.current?.scrollTo({
            top: messagesContainerRef.current.scrollHeight,
            behavior: 'smooth',
        });
    };

    return (
        <>
            {chapters && chapters.length > 0 && (
                <Box>
                    <Stack direction="row" spacing={2} py={2} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                        {currChapIndex > 0 ? (
                            <Button variant="outlined" startIcon={<NavigateBeforeRoundedIcon />} onClick={handleChapPrior}>
                                Back{' '}
                            </Button>
                        ) : (
                            <Button
                                disabled
                                sx={{ backgroundColor: 'rgba(255, 255, 255, 0.25)' }}
                                variant="outlined"
                                startIcon={<NavigateBeforeRoundedIcon />}
                                onClick={handleChapPrior}
                            >
                                Back{' '}
                            </Button>
                        )}
                        <TextField
                            focused={true}
                            size="small"
                            variant="outlined"
                            select
                            label="Chapter"
                            value={chapterId}
                            sx={{
                                '& .MuiFormHelperText-root': { color: 'primary' },
                                '& .MuiSelect-select': { color: '#fff', border: '#fff', width: '84px' },
                                '& .MuiSelect-icon': { color: '#fff' },
                                '& .MuiMenuItem-root': { borderColor: '#fff' },
                            }}
                        >
                            {chapters?.map((id, index) => (
                                <MenuItem key={id} value={id} onClick={(e) => handleChap(index, id)}>
                                    Chapter {index + 1}
                                </MenuItem>
                            ))}
                        </TextField>
                        {chapters && chapters.length > 0 && currChapIndex < chapters.length - 1 ? (
                            <Button variant="outlined" endIcon={<NavigateNextRoundedIcon />} onClick={handleChapNext}>
                                Next
                            </Button>
                        ) : (
                            <Button
                                disabled
                                sx={{ backgroundColor: 'rgba(255, 255, 255, 0.25)' }}
                                variant="outlined"
                                endIcon={<NavigateNextRoundedIcon />}
                                onClick={handleChapNext}
                            >
                                Next
                            </Button>
                        )}
                    </Stack>
                    {!audioLoading && (
                        <Box bgcolor="#f5f5f5" p={2}>
                            <AudioPlayer sub={localStorage.getItem('sub')} src={audioSrc} dur={duration} />
                        </Box>
                    )}
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#eee' }}>
                        <div style={{ width: `${pdfWidth}`, border: '1px solid #eeeeee', marginTop: '4px' }}>
                            <div
                                style={{
                                    alignItems: 'center',
                                    backgroundColor: '#eeeeee',
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: '4px',
                                }}
                            >
                                <div style={{ padding: '0px 2px' }}>
                                    <ZoomOutButton />
                                </div>

                                <div style={{ padding: '0px 2px' }}>
                                    <ZoomPopover />
                                </div>

                                <div style={{ padding: '0px 2px' }}>
                                    <ZoomInButton />
                                </div>

                                <div style={{ padding: '0px 2px' }}>
                                    <GoToFirstPageButton />
                                </div>

                                <div style={{ padding: '0px 2px' }}>
                                    <GoToPreviousPage />
                                </div>

                                <div style={{ padding: '0px 2px', width: '4rem' }}>
                                    <CurrentPageInput />
                                </div>

                                <div style={{ padding: '0px 2px' }}>
                                    <GoToNextPageButton />
                                </div>

                                <div style={{ padding: '0px 2px' }}>
                                    <GoToLastPageButton />
                                </div>
                            </div>
                            <Collapse in={error.length > 0}>
                                <Alert
                                    severity="warning"
                                    variant="filled"
                                    sx={{ '& .MuiAlert-icon': { fontSize: '30px' }, fontSize: '18px' }}
                                    color="warning"
                                    onClose={() => {
                                        setError('');
                                    }}
                                >
                                    {error}
                                </Alert>
                            </Collapse>
                            <Collapse in={upgrade && currChapIndex !== 0}>
                                <Alert
                                    severity="warning"
                                    variant="filled"
                                    sx={{ '& .MuiAlert-icon': { fontSize: '30px' }, fontSize: '18px' }}
                                    color="warning"
                                    onClose={() => {
                                        setUpgrade(false);
                                    }}
                                >
                                    You have another textbook unlocked under your current subscription plan. Upgrade to
                                    access unlimited summaries by clicking{' '}
                                    <Link
                                        underline="hover"
                                        onClick={() => {
                                            navigate('/pricing');
                                        }}
                                        sx={{ color: 'common.white', '&:hover': { cursor: 'pointer' } }}
                                    >
                                        <b>here</b>
                                    </Link>
                                    !
                                </Alert>
                            </Collapse>
                            <Collapse in={!unlocked && hasSub && currChapIndex !== 0 && !needsUpgrade}>
                                <Alert
                                    severity="warning"
                                    variant="filled"
                                    color="warning"
                                    sx={{ '& .MuiAlert-icon': { fontSize: '26px' }, fontSize: '18px' }}
                                    onClose={() => {
                                        setUnlocked(true);
                                    }}
                                >
                                    Chapter {currChapIndex + 1} locked. If subscribed, please log in. Otherwise, unlock
                                    all chapter summaries by clicking{' '}
                                    <Link
                                        underline="hover"
                                        onClick={() => {
                                            navigate('/pricing');
                                        }}
                                        sx={{ color: 'common.white', '&:hover': { cursor: 'pointer' } }}
                                    >
                                        <b>here</b>
                                    </Link>
                                    !
                                </Alert>
                            </Collapse>
                            <Collapse in={!subscribed && currChapIndex !== 0 && !needsUpgrade}>
                                <Alert
                                    severity="info"
                                    variant="filled"
                                    sx={{ '& .MuiAlert-icon': { fontSize: '30px' }, fontSize: '18px' }}
                                    color="info"
                                    onClose={() => {
                                        setSubscribed(true);
                                    }}
                                >
                                    This chapter is locked. Unlock all of this textbook's chapter summaries by clicking{' '}
                                    <Link
                                        underline="hover"
                                        onClick={handleAddTextbook}
                                        sx={{ color: 'common.white', '&:hover': { cursor: 'pointer' } }}
                                    >
                                        <b>here</b>
                                    </Link>
                                    !<Typography>(NOTE: With your current subscription plan, you can only unlock one textbook's worth of chapter summaries.)</Typography>
                                </Alert>
                            </Collapse>
                            {pageLoading ? (
                                <LoadingSpinner text={'Loading summary...'} />
                            ) : (
                                <>
                                    <div className="viewer">
                                        {pdfFile && (
                                            <>
                                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                                                    <Viewer
                                                        fileUrl={pdfFile}
                                                        onZoom={handleZoom}
                                                        plugins={[pageNavigationPluginInstance, zoomPluginInstance]}
                                                    />
                                                </Worker>
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    <Box px="20vw" pt="5vh">
                        <Typography variant={!isNotMobile ? 'h4' : 'h3'} fontWeight={700}>
                            Chat with this summary:
                        </Typography>
                        <Collapse in={chatError.length > 0}>
                            <Alert
                                severity="error"
                                variant="filled"
                                color="error"
                                sx={{ '& .MuiAlert-icon': { fontSize: isNotMobile ? '30px' : '24px' }, fontSize: '18px' }}
                                onClose={() => {
                                    setChatError('');
                                }}
                            >
                                {chatError}
                            </Alert>
                        </Collapse>
                        <Stack
                            ref={messagesContainerRef}
                            spacing={2}
                            mt={2}
                            sx={{ height: userMessages.length > 0 ? '68vh' : 0, overflowY: 'auto', pb: isNotMobile ? 4 : 2 }}
                        >
                            {userMessages
                                .slice()
                                .reverse()
                                .map((message, index) => (
                                    <div key={index}>
                                        {/* User Message */}
                                        <Stack direction="row" py={0} spacing={2} alignItems="center">
                                            <Card
                                                sx={{
                                                    py: 0,
                                                    px: 2,
                                                    border: '1px solid darkGray',
                                                    borderRadius: 1,
                                                    bgcolor: '#1e2f44',
                                                    width: '100%',
                                                    minHeight: 48,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        minHeight: '48px',
                                                        alignContent: 'center',
                                                        fontSize: isNotMobile ? 16 : 14,
                                                    }}
                                                >
                                                    <Markdown>{userMessages[index]}</Markdown>
                                                </div>
                                            </Card>
                                            <Avatar
                                                alt="You"
                                                sx={{
                                                    bgcolor: '#eef5ff',
                                                    width: isNotMobile ? 48 : 40,
                                                    height: isNotMobile ? 48 : 40,
                                                }}
                                            >
                                                <PersonIcon sx={{ color: 'primary.main', fontSize: 25 }} />
                                            </Avatar>
                                        </Stack>

                                        {/* Corresponding System Message */}
                                        <Stack direction="row" mt={2} py={0} spacing={2} alignItems="center">
                                            <Avatar
                                                alt="Chat Assistant"
                                                src={logo}
                                                sx={{
                                                    bgcolor: '#eef5ff',
                                                    width: isNotMobile ? 48 : 40,
                                                    height: isNotMobile ? 48 : 40,
                                                }}
                                            />
                                            <Card
                                                sx={{
                                                    py: 0,
                                                    px: 2,
                                                    border: '1px solid darkGray',
                                                    borderRadius: 1,
                                                    bgcolor: '#1e2f44',
                                                    width: '100%',
                                                }}
                                            >
                                                {chatLoading && index === systemMessages.length - 1 ? (
                                                    <Box
                                                        style={{
                                                            minHeight: '48px',
                                                            alignContent: 'center',
                                                            fontSize: isNotMobile ? 16 : 14,
                                                            padding: 10,
                                                        }}
                                                    >
                                                        <Markdown>Loading...</Markdown>
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        style={{
                                                            minHeight: '48px',
                                                            alignContent: 'center',
                                                            fontSize: isNotMobile ? 16 : 14,
                                                            padding: 10,
                                                        }}
                                                    >
                                                        <Markdown>{systemMessages[index]}</Markdown>
                                                    </Box>
                                                )}
                                            </Card>
                                        </Stack>
                                    </div>
                                ))}
                        </Stack>
                        <Tooltip
                            title={
                                localStorage.getItem('sub') !== 'premium'
                                    ? 'Upgrade to Premium to unlock this feature!'
                                    : ''
                            }
                            placement="top"
                            followCursor={true}
                        >
                            <Stack>
                                <Stack sx={{ bgcolor: '#1e2f45' }}>
                                    <TextField
                                        required
                                        fullWidth
                                        multiline
                                        value={question}
                                        disabled={localStorage.getItem('sub') !== 'premium'}
                                        placeholder="Ask me anything about this chapter summary..."
                                        onChange={(e) => setQuestion(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Button
                                                        size="small"
                                                        component="label"
                                                        variant="contained"
                                                        type="submit"
                                                        onClick={chatHandler}
                                                        disabled={chatLoading || !question.length}
                                                        disableElevation={true}
                                                    >
                                                        Submit
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Stack>
                            </Stack>
                        </Tooltip>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default SummaryScreen;
